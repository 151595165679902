const colors = {
  primary: '#3498dd',
  secondary: '#2ecc71',
  disabled: '#95a5a6',
  success: '#27ae60',
  error: '#e74c3c',
  borders: { light: '#FFFFFF', gray: '#888888', dark: '#000000' },
  shadows: { light: '#FFFFFF', dark: '#000000' },
  fromGradient: '#3498db',
  toGradient: '#2ecc71',
  stopGradient: '50%'
};

module.exports = colors;