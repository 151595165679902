const template = {
 app_name: 'Modus Nuggets 8',
 client_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YThjZWE4NWI0YzU2NTVlODI5YjkxYSIsIm5hbWUiOiJNb2R1cyBOdWdnZXRzIHNleWkiLCJ0eXBlIjoiRU5USVRZIiwiaWF0IjoxNzA1NTYxNzY5fQ.dzmIzTdbFEi3KCaT1_HC9SLLI4kk_O6DeIco-2LAnSU',
 payment_engine_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YThjZWE4NWI0YzU2NTVlODI5YjkxYSIsIm5hbWUiOiJNb2R1cyBOdWdnZXRzIHNleWkiLCJ0eXBlIjoiRU5USVRZIiwiaWF0IjoxNzA1NTYxNzY5fQ.dzmIzTdbFEi3KCaT1_HC9SLLI4kk_O6DeIco-2LAnSU',
 app_url: '',
 about_us: { description: 'description' },
 newsletter: { description: 'description', sender:  { email: 'newsletter@example.com' } } ,
 socials: { facebook: '', instagram: '', 
 twitter: '', linkedin: '', 
 whatsapp: '' },
 contact_details: { email: 'contact@example.com', phone: '+1 123-456-7890' },
 contact_us: { recipient: { email: 'contact_us@example.com' } } 
};

module.exports = template;