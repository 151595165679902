"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomePage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var products_1 = require("../../api/calls/product/products");
var categories_1 = require("../../api/calls/product/categories");
var selection_1 = require("../../reducers/slices/selection");
var react_router_dom_1 = require("react-router-dom");
var recently_viewed_1 = require("../../api/calls/product/recently_viewed");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var calculateDiscountPercentage_1 = require("../../helpers/calculateDiscountPercentage");
var HomePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    // const { products } = useSelector<any, IBasketSliceState>((state) => state.basketState);
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_1.useState)([]), allProducts = _a[0], setAllProducts = _a[1];
    var _b = (0, react_1.useState)([]), categories = _b[0], setCategories = _b[1];
    var _c = (0, react_1.useState)([]), recentlyViewed = _c[0], setRecentlyViewed = _c[1];
    var _d = (0, react_1.useState)([]), onPromotionProducts = _d[0], setOnPromotionProducts = _d[1];
    var _e = (0, react_1.useState)(0), onPromotionTotalRecords = _e[0], setOnPromotionTotalRecords = _e[1];
    var _f = (0, react_1.useState)(1), onPromotionCurrentPage = _f[0], setOnPromotionCurrentPage = _f[1];
    var _g = (0, react_1.useState)(20), onPromotionRecordsPerPage = _g[0], setOnPromotionRecordsPerPage = _g[1];
    var _h = (0, react_1.useState)(1), currentPage = _h[0], setCurrentPage = _h[1];
    var _j = (0, react_1.useState)(0), totalRecords = _j[0], setTotalRecords = _j[1];
    var _k = (0, react_1.useState)(20), recordsPerPage = _k[0], setRecordsPerPage = _k[1];
    var _l = (0, react_1.useState)([]), top10Products = _l[0], setTop10Products = _l[1];
    var _m = (0, react_1.useState)(1), top10ProductsCurrentPage = _m[0], setTop10ProductsCurrentPage = _m[1];
    var _o = (0, react_1.useState)(0), top10ProductsTotalRecords = _o[0], setTop10ProductsTotalRecords = _o[1];
    var _p = (0, react_1.useState)(10), top10ProductsRecordsPerPage = _p[0], setTop10ProductsRecordsPerPage = _p[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (user.user._id) {
            (0, recently_viewed_1.getRecentlyViewedProducts)({
                user_id: (_a = user.user._id) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                var _a;
                // console.log("Recent products fetched", response);
                setRecentlyViewed((_a = response.content) !== null && _a !== void 0 ? _a : []);
            });
        }
    }, [user]);
    (0, react_1.useEffect)(function () {
        (0, categories_1.getCategories)({
            page: "1",
        }).then(function (response) {
            var _a;
            var success = response.success;
            if (success) {
                var product_category_list = response.content.product_category_list;
                var size = 5;
                var filtered_list = (_a = product_category_list === null || product_category_list === void 0 ? void 0 : product_category_list.filter(function (category) { return category.image_url; })) !== null && _a !== void 0 ? _a : []; //.slice(0, size);
                setCategories(filtered_list);
            }
            else {
                setCategories([]);
            }
        });
        (0, products_1.getProducts)({
            page: currentPage.toString(),
            size: recordsPerPage.toString(),
        }).then(function (response) {
            var _a, _b;
            // console.log("products response", response);
            setAllProducts((_a = response.content.product_list) !== null && _a !== void 0 ? _a : []);
            setTotalRecords((_b = response.content.count) !== null && _b !== void 0 ? _b : 0);
        });
    }, [currentPage]);
    (0, react_1.useEffect)(function () {
        (0, products_1.getProducts)({
            page: onPromotionCurrentPage.toString(),
            size: onPromotionRecordsPerPage.toString(),
            on_promotion: true,
        }).then(function (response) {
            var _a, _b;
            // console.log("products response", response);
            setOnPromotionProducts((_a = response.content.product_list) !== null && _a !== void 0 ? _a : []);
            setOnPromotionTotalRecords((_b = response.content.count) !== null && _b !== void 0 ? _b : 0);
        });
    }, [onPromotionCurrentPage]);
    (0, react_1.useEffect)(function () {
        (0, products_1.getProducts)({
            page: top10ProductsCurrentPage.toString(),
            size: top10ProductsRecordsPerPage.toString(),
            recommended: true,
        }).then(function (response) {
            var _a, _b;
            // console.log("recommedned products response", response);
            setTop10Products((_a = response.content.product_list) !== null && _a !== void 0 ? _a : []);
            setTop10ProductsTotalRecords((_b = response.content.count) !== null && _b !== void 0 ? _b : 0);
        });
    }, [top10ProductsCurrentPage]);
    return (react_1.default.createElement("div", { className: "pt-4" },
        react_1.default.createElement(doshx_controls_web_1.CarouselControl, { pagination: { bulletColor: "bg-primary" }, slides: categories.map(function (category, index) { return ({
                textBlock: {
                    // superTitle: "Most popular",
                    title: category.name,
                    description: category.description,
                    position: "top left",
                },
                backgroundColor: "bg-white",
                imageSrc: category.image_url,
                onClick: function () {
                    var path = "/shop";
                    var _id = category._id, name = category.name;
                    if (_id && name) {
                        dispatch((0, selection_1.setSelectedCategory)({ item: category, level: 0 }));
                        navigate("".concat(path, "/category/").concat(0, "/").concat(category._id, "/").concat(encodeURI(category.name)));
                    }
                    else {
                        navigate(path);
                    }
                },
            }); }) }),
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement(doshx_controls_web_1.ProductCarouselBigControl, { loop: false, shortSwipes: true, colors: {
                    discount: {
                        textColor: "text-shadows-light",
                        // iconColor: `text-primary`,
                        amountColor: "text-primary",
                        backgroundColor: "bg-primary",
                    },
                }, category: {
                    name: "Recently Added",
                    color: "text-secondary",
                }, products: top10Products.map(function (product, index) {
                    return {
                        index: index,
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        // rating: product.rating.average,
                        borderColor: "border-borders-light",
                        shadowColor: "shadow-shadows-dark",
                        textColor: "text-primary",
                        amountColor: product.on_promotion ? "text-disabled" : "text-primary",
                        starActiveColor: "text-borders-light",
                        starInactiveColor: "text-borders-light",
                        loaderColor: "text-primary",
                        backgroundColor: "bg-white",
                        discountLabel: (0, calculateDiscountPercentage_1.generateDiscountLabel)(product.on_promotion, product.price, product.promotion_price),
                        discountAmount: product.on_promotion ? product.promotion_price : "",
                    };
                }), onClick: function (product) {
                    // console.log("product", product);
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                }, pagination: {
                    currentPage: top10ProductsCurrentPage,
                    totalRecords: top10ProductsTotalRecords,
                    recordsPerPage: top10ProductsRecordsPerPage,
                    onClick: function (pageNumber) {
                        setTop10ProductsCurrentPage(pageNumber);
                    },
                    // position: `center`,
                    colors: {
                        selected: {
                            backgroundColor: "bg-secondary",
                            textColor: "text-white",
                            borderColor: "border-borders-light",
                        },
                        unselected: {
                            backgroundColor: "bg-secondary",
                            textColor: "text-white",
                            borderColor: "border-borders-light",
                        },
                    },
                } }),
            react_1.default.createElement(doshx_controls_web_1.ProductCarouselBigControl, { loop: false, shortSwipes: true, colors: {
                    discount: {
                        textColor: "text-shadows-light",
                        // iconColor: `text-primary`,
                        amountColor: "text-primary",
                        backgroundColor: "bg-primary",
                    },
                }, category: {
                    name: "Popular Search",
                    color: "text-secondary",
                }, products: allProducts.map(function (product, index) {
                    return {
                        index: index,
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        // rating: product.rating.average,
                        borderColor: "border-borders-light",
                        shadowColor: "shadow-shadows-dark/5",
                        textColor: "text-primary",
                        amountColor: product.on_promotion ? "text-disabled" : "text-primary",
                        starActiveColor: "text-borders-light",
                        starInactiveColor: "text-borders-light",
                        loaderColor: "text-primary",
                        backgroundColor: "bg-white",
                        discountLabel: (0, calculateDiscountPercentage_1.generateDiscountLabel)(product.on_promotion, product.price, product.promotion_price),
                        discountAmount: product.on_promotion ? product.promotion_price : "",
                    };
                }), onClick: function (product) {
                    // console.log("product", product);
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                }, pagination: {
                    currentPage: top10ProductsCurrentPage,
                    totalRecords: top10ProductsTotalRecords,
                    recordsPerPage: top10ProductsRecordsPerPage,
                    onClick: function (pageNumber) {
                        setTop10ProductsCurrentPage(pageNumber);
                    },
                    colors: {
                        selected: {
                            backgroundColor: "bg-secondary",
                            textColor: "text-white",
                            borderColor: "border-borders-light",
                        },
                        unselected: {
                            backgroundColor: "bg-secondary",
                            textColor: "text-white",
                            borderColor: "border-borders-light",
                        },
                    },
                } }),
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { borderColor: "border-transparent" },
                react_1.default.createElement(doshx_controls_web_1.CommitmentsControl, { orientation: "horizontal", commitments: [
                        // { icon: faTruck, title: "Free Delivery", description: "From R40" },
                        { icon: pro_solid_svg_icons_1.faStar, title: "Best Quality", description: "Brand" },
                        // { icon: faHistory, title: "1 Year", description: "For Free Return" },
                        // { icon: faComments, title: "Feedback", description: "99% Real Data" },
                        { icon: pro_solid_svg_icons_1.faCreditCard, title: "Payment", description: "Secure" },
                    ] })),
            onPromotionProducts && onPromotionProducts.length > 0 && (react_1.default.createElement(doshx_controls_web_1.ProductCarouselBigControl, { loop: false, shortSwipes: true, colors: {
                    discount: {
                        textColor: "text-shadows-light",
                        // iconColor: `text-primary`,
                        amountColor: "text-primary",
                        backgroundColor: "bg-primary",
                    },
                }, category: {
                    name: "Hot Sale",
                    color: "text-secondary",
                }, button: {
                    backgroundColor: "bg-secondary",
                    label: "Add to cart",
                    textColor: "text-white",
                    onClick: function (product) {
                        // console.log("product", product);
                        dispatch((0, selection_1.setSelectedProduct)(product));
                        navigate("/product/".concat(product._id));
                    },
                }, products: onPromotionProducts.map(function (product, index) {
                    return {
                        index: index,
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        // rating: product.rating.average,
                        borderColor: "border-borders-light",
                        shadowColor: "shadow-shadows-dark/5",
                        textColor: "text-primary",
                        amountColor: product.on_promotion ? "text-disabled" : "text-primary",
                        starActiveColor: "text-borders-light",
                        starInactiveColor: "text-borders-light",
                        loaderColor: "text-primary",
                        backgroundColor: "bg-white",
                        discountLabel: (0, calculateDiscountPercentage_1.generateDiscountLabel)(product.on_promotion, product.price, product.promotion_price),
                        discountAmount: product.on_promotion ? product.promotion_price : "",
                    };
                }), onClick: function (product) {
                    // console.log("product", product);
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                }, pagination: {
                    currentPage: onPromotionCurrentPage,
                    totalRecords: onPromotionTotalRecords,
                    recordsPerPage: onPromotionRecordsPerPage,
                    onClick: function (pageNumber) {
                        setOnPromotionCurrentPage(pageNumber);
                    },
                    colors: {
                        selected: {
                            backgroundColor: "bg-secondary",
                            textColor: "text-white",
                            borderColor: "border-borders-light",
                        },
                        unselected: {
                            backgroundColor: "bg-secondary",
                            textColor: "text-white",
                            borderColor: "border-borders-light",
                        },
                    },
                } })),
            recentlyViewed && recentlyViewed.length > 0 && (react_1.default.createElement(doshx_controls_web_1.ProductCarouselSmallControl, { shortSwipes: true, category: {
                    name: "Recently viewed",
                    color: "text-secondary",
                }, products: recentlyViewed.map(function (product, index) {
                    return {
                        _id: product._id,
                        image: product.image_url,
                        title: product.title,
                        amount: product.price,
                        // rating: product.rating.average,
                        borderColor: "border-borders-light",
                        shadowColor: "shadow-shadows-light",
                        textColor: "text-primary",
                        amountColor: product.on_promotion ? "text-disabled" : "text-primary",
                        starActiveColor: "text-borders-light",
                        starInactiveColor: "text-borders-light",
                        loaderColor: "text-primary",
                        discountLabel: (0, calculateDiscountPercentage_1.generateDiscountLabel)(product.on_promotion, product.price, product.promotion_price),
                        discountAmount: product.on_promotion ? product.promotion_price : "",
                        colors: {
                            discount: {
                                // textColor: `text-success`,
                                // iconColor: `text-primary`,
                                amountColor: "text-primary",
                                // backgroundColor: `bg-red-500`,
                            },
                        },
                    };
                }), onClick: function (product) {
                    // console.log("product", product);
                    dispatch((0, selection_1.setSelectedProduct)(product));
                    navigate("/product/".concat(product._id));
                } })))));
};
exports.HomePage = HomePage;
